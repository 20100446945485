import clsx from 'clsx'
import type { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'

const ButtonDefaultElement = 'button'

const variants = {
  primary: {
    buttonClassNames: clsx(
      'text-green-43 border-green-43',
      'hover:text-white hover:bg-green-43 focus:text-white focus:bg-green-43'
    ),
  },
  whiteOutline: {
    buttonClassNames: clsx(
      'text-white border-white bg-transparent',
      'hover:text-gray-18 hover:bg-white focus:text-gray-18 focus:bg-white'
    ),
  },
}

interface OwnProps {
  variant?: keyof typeof variants
}
export type ButtonProps<
  T extends React.ElementType = typeof ButtonDefaultElement
> = PolymorphicPropsWithoutRef<OwnProps, T>

export const Button = <
  T extends React.ElementType = typeof ButtonDefaultElement
>({
  as,
  variant = 'primary',
  className,
  children,
  ...props
}: ButtonProps<T>) => {
  const Element: React.ElementType = as || ButtonDefaultElement
  const variantClasses = variants[variant]

  return (
    <Element
      className={clsx(
        className,
        variantClasses.buttonClassNames,
        'uppercase font-semibold border rounded-[24px] px-8 py-3 transition',
        'focus:outline-none focus:ring'
      )}
      {...props}
    >
      <div className={clsx('sans-12-1_5 tracking-caps')}>{children}</div>
    </Element>
  )
}

import clsx from 'clsx'

export const MAIN_CONTENT_ID = 'main-content'

export const SkipNavLink = () => {
  return (
    <a
      className={clsx(
        'fixed pointer-events-none top-3 left-3 opacity-0 transition z-30 p-4',
        'bg-green-43 block rounded-md focus:ring',
        'focus:opacity-100 focus:pointer-events-auto focus:outline-none'
      )}
      href={`#${MAIN_CONTENT_ID}`}
    >
      <div className="font-medium text-white sans-16-1_5">Skip To Content</div>
    </a>
  )
}

export const GraphqlIcon = (props: React.SVGAttributes<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g
          fill="currentColor"
          fillRule="nonzero"
          stroke="currentColor"
          strokeWidth={0.5}
        >
          <path d="M4.794 17.177l-.724-.418 8.077-13.99.724.42z" />
          <path d="M3.903 15.64H20.06v.838H3.903z" />
          <path d="M12.304 20.777l-8.08-4.665.418-.724 8.08 4.665zm7.02-12.16l-8.082-4.664.419-.725 8.08 4.666z" />
          <path d="M4.644 8.614l-.419-.724 8.088-4.666.419.724z" />
          <path d="M19.174 17.177l-8.078-13.99.724-.419 8.079 13.99zM4.544 7.335h.837v9.33h-.837zm14.042 0h.837v9.33h-.837z" />
          <path d="M12.161 20.422l-.365-.633 7.028-4.057.366.633z" />
          <path d="M20.533 16.938a1.764 1.764 0 01-2.406.646 1.764 1.764 0 01-.646-2.406 1.764 1.764 0 012.406-.646 1.755 1.755 0 01.645 2.406M6.48 8.823a1.764 1.764 0 01-2.406.645 1.764 1.764 0 01-.646-2.406 1.764 1.764 0 012.406-.646 1.764 1.764 0 01.646 2.406m-3.046 8.116a1.764 1.764 0 01.645-2.406 1.764 1.764 0 012.406.646 1.761 1.761 0 01-3.052 1.76m14.052-8.115a1.764 1.764 0 01.646-2.406 1.764 1.764 0 012.406.646 1.764 1.764 0 01-.646 2.406 1.764 1.764 0 01-2.406-.646m-5.502 13.052a1.76 1.76 0 110-3.52 1.76 1.76 0 010 3.52m0-16.23a1.758 1.758 0 01-1.76-1.76 1.76 1.76 0 111.76 1.76" />
        </g>
      </g>
    </svg>
  )
}

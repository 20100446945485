import { graphql, useStaticQuery } from 'gatsby'
import { SettingsQuery } from '../types.gql'

export function useSettings() {
  const data = useStaticQuery<SettingsQuery>(graphql`
    query Settings {
      allPrismicSettings(limit: 1) {
        nodes {
          data {
            site_name {
              text
            }
            site_description {
              text
            }
            site_copyright {
              text
            }
            patient_portal_url
          }
        }
      }
    }
  `)

  return data.allPrismicSettings.nodes[0]
}

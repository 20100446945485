import clsx from 'clsx'
import type { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'

const BoundedBoxDefaultElement = 'div'

const twVariants = {
  base: {
    pxClassName: 'px-6 sm:px-8 lg:px-10',
  },
}

interface OwnProps {
  variant?: keyof typeof twVariants
  maxWidthClassName?: string
  innerClassName?: string
}

export type BoundedBoxTwProps<
  T extends React.ElementType = typeof BoundedBoxDefaultElement
> = PolymorphicPropsWithoutRef<OwnProps, T>

export const BoundedBoxTw = <
  T extends React.ElementType = typeof BoundedBoxDefaultElement
>({
  as,
  variant = 'base',
  maxWidthClassName = 'max-w-lg',
  className,
  children,
  innerClassName,
  ...props
}: BoundedBoxTwProps<T>) => {
  const Element: React.ElementType = as || BoundedBoxDefaultElement
  const variantClasses = twVariants[variant]

  return (
    <Element className={clsx(className, variantClasses.pxClassName)} {...props}>
      <div
        className={clsx(
          'w-full mx-auto h-full',
          maxWidthClassName,
          innerClassName
        )}
      >
        {children}
      </div>
    </Element>
  )
}

import { graphql, useStaticQuery } from 'gatsby'
import { HeaderNavigationQuery } from '../types.gql'

export function useHeaderNavigation() {
  const data = useStaticQuery<HeaderNavigationQuery>(
    graphql`
      query HeaderNavigation {
        prismicNavigation(uid: { eq: "header" }) {
          data {
            main {
              ... on PrismicNavigationMainNavItem {
                primary {
                  name
                  link {
                    url
                  }
                }
                items {
                  sub_name
                  sub_link {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return data.prismicNavigation
}

import clsx from 'clsx'

const Line = ({ className }: React.ComponentProps<'div'>): JSX.Element => {
  return (
    <div
      className={clsx(
        'h-[1px] bg-gray-18 w-6 rounded-lg transition origin-center',
        className
      )}
    />
  )
}

interface Props {
  isOpen?: boolean
}

export const Hamburger = ({ isOpen = false }: Props): JSX.Element => {
  return (
    <div className="grid gap-[6px]">
      <Line
        className={clsx(
          'transform',
          isOpen ? '-rotate-45 translate-y-[7px]' : 'translate-y-0'
        )}
      />
      <Line className={clsx(isOpen ? 'opacity-0' : 'opacity-100')} />

      <Line
        className={clsx(
          'transform',
          isOpen ? 'rotate-45 translate-y-[-7px]' : 'translate-y-0'
        )}
      />
    </div>
  )
}

import { WrapRootElementBrowserArgs } from 'gatsby'

import '@fontsource/poppins/400.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'
import '@fontsource/cardo/400.css'
import '@fontsource/cardo/700.css'
import '@reach/tabs/styles.css'
import 'keen-slider/keen-slider.min.css'
import './global.css'

import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { DevPanel } from './components/DevPanel'
import { __DEV__ } from './lib/constants'
import { SkipNavLink, MAIN_CONTENT_ID } from './components/Header/SkipNavLink'

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs) => {
  return (
    <div className="flex flex-col min-h-screen">
      <SkipNavLink />
      {__DEV__ && <DevPanel />}

      <Header />
      <main className="flex flex-col flex-grow" id={MAIN_CONTENT_ID}>
        {element}
      </main>
      <Footer className="mt-auto" />
    </div>
  )
}

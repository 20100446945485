import * as React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { isAnchor } from '../lib/isAnchor'
import { isInternal } from '../lib/isInternal'

type Props = React.ComponentProps<'a'>

export const Link = React.forwardRef<HTMLAnchorElement, Props>(
  ({ href, ...props }, ref) => {
    if (!href) return <a ref={ref} {...props} />
    if (isInternal(href)) {
      //@ts-ignore
      return <GatsbyLink ref={ref} to={href} {...props} />
    }

    const isAnchorLink = isAnchor(href)

    return (
      <a
        ref={ref}
        href={isAnchorLink ? `#${href.split('#')[1]}` : href}
        target={isAnchorLink ? undefined : '_blank'}
        rel={isAnchorLink ? undefined : 'noopener noreferrer nofollow'}
        {...props}
      />
    )
  }
)

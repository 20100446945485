import { BoundedBoxTw } from '../BoundedBox'
import { Logo } from '../Logo'
import { Link } from '../Link'
import clsx from 'clsx'

interface Props {
  closeMobileNav: () => void
}

export const LogoContainer = ({ closeMobileNav }: Props): JSX.Element => {
  return (
    <BoundedBoxTw
      onClick={closeMobileNav}
      className={clsx(
        'relative border-r border-b border-green-48 bg-white h-[90px] z-20',
        'sm:py-6 sm:border-b-0 sm:h-auto'
      )}
      innerClassName="flex items-center"
    >
      <Link
        // The negative margin here is to offset some transparent pixels found
        // in the SVG what.
        href="/"
        className="w-[220px] -mb-1 block sm:w-[330px] focus:ring focus:outline-none"
      >
        <Logo className="w-full" />
      </Link>
    </BoundedBoxTw>
  )
}

import * as React from 'react'
import { AnimatePresence } from 'framer-motion'
import clsx from 'clsx'
import { Drawer, Overlay } from './Drawer'
import { HamburgerButton } from './HamburgerButton'
import { LogoContainer } from './LogoContainer'
import { DesktopNavigation } from './DesktopNavigation'
import { ContactInfo } from './ContactInfo'

export const Header = (): JSX.Element => {
  // TODO: hoist on route change to close navigation at the app level.
  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false)

  const toggleMobileNav = () => setIsMobileNavOpen((prev) => !prev)
  const closeMobileNav = () => setIsMobileNavOpen(false)

  // TODO: Fix this, maybe use body-scroll-lock lib.
  const toggleNavWithLocking = () => {
    toggleMobileNav()
  }

  return (
    <header
      className={clsx(
        'grid relative grid-cols-[1fr,90px]',
        'sm:h-auto sm:grid-cols-2 sm:border-b sm:border-green-48',
        'md:grid-cols-[3fr,2.25fr]',
        'lg:grid-cols-[3fr,2fr]'
      )}
    >
      <AnimatePresence>
        {isMobileNavOpen && <Overlay key="overlay" />}
        {isMobileNavOpen && (
          <Drawer key="drawer" closeMobileNav={closeMobileNav} />
        )}
      </AnimatePresence>

      <LogoContainer closeMobileNav={closeMobileNav} />
      <HamburgerButton
        isOpen={isMobileNavOpen}
        onClick={toggleNavWithLocking}
      />
      <ContactInfo />
      <DesktopNavigation />
    </header>
  )
}

/**
 * Checks if the provided string is an internal link. A link is considered to be internal
 * if it does not include an `http` prefix.
 *
 * @param url The string to check if it is an internal link.
 * @returns `true` if the link is internal, `false` otherwise.
 *
 * @example
 * isInternal('/internal-link')
 * // => true
 *
 * isInternal('https://external.com')
 * // => false
 *
 * isInternal('//external.com')
 * // => false
 */
export const isInternal = (url: string): boolean => /^\/(?!\/)/.test(url)

import { graphql, useStaticQuery } from 'gatsby'
import { FooterNavigationQuery } from '../types.gql'

export function useFooterNavigation() {
  const data = useStaticQuery<FooterNavigationQuery>(
    graphql`
      query FooterNavigation {
        prismicNavigation(uid: { eq: "footer" }) {
          data {
            main {
              ... on PrismicNavigationMainNavItem {
                primary {
                  name
                  link {
                    url
                  }
                }
                items {
                  sub_name
                  sub_link {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return data.prismicNavigation
}

import { useState } from 'react'
import clsx from 'clsx'
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion'

import { useHeaderNavigation } from '../../hooks/useHeaderNavigation'
import { useSettings } from '../../hooks/useSettings'
import { BoundedBoxTw } from '../BoundedBox'
import { Button } from '../Button'
import { Link } from '../Link'

const MotionLink = motion(Link)

interface SubItem {
  sub_name?: string
  sub_link: { url?: string }
}

interface NavItemProps {
  link?: { url?: string }
  name?: string
  subItems?: SubItem[]
}

const NavItem = ({ link, name, subItems = [] }: NavItemProps) => {
  const [isFocused, setIsFocused] = useState(false)

  const showSubMenu = () => setIsFocused(true)
  const hideSubMenu = () => setIsFocused(false)

  return (
    <motion.li
      layout="position"
      onHoverStart={showSubMenu}
      onHoverEnd={hideSubMenu}
      className="grid grid-flow-col select-none gap-x-4"
    >
      <motion.div
        layout="position"
        className={clsx(
          'sans-12-1_5 uppercase font-semibold tracking-caps',
          'transition hover:text-green-43 focus:text-green-43'
        )}
      >
        <MotionLink
          layout="position"
          onFocus={showSubMenu}
          onBlur={hideSubMenu}
          href={link?.url}
          className="focus:outline-none focus:ring"
        >
          {name}
        </MotionLink>
      </motion.div>

      <AnimatePresence>
        {subItems.length > 0 && isFocused && (
          <motion.ul
            layout="position"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {subItems.map((subItem, idx) => (
              <motion.li key={idx} layout="position">
                <MotionLink
                  layout="position"
                  onFocus={showSubMenu}
                  onBlur={hideSubMenu}
                  className={clsx(
                    'sans-12-1_5 block font-semibold text-green-43',
                    'hover:underline focus:underline focus:outline-none',
                    'focus:ring'
                  )}
                  href={subItem.sub_link.url}
                >
                  {subItem.sub_name}
                </MotionLink>
              </motion.li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </motion.li>
  )
}

export const DesktopNavigation = (): JSX.Element => {
  const navigation = useHeaderNavigation()
  const settings = useSettings()

  const portalUrl = settings.data?.patient_portal_url

  return (
    <BoundedBoxTw
      as="nav"
      maxWidthClassName="max-w-none"
      className={clsx(
        'hidden border-t py-5 col-span-full bg-green-84 border-green-48',
        'sm:block'
      )}
      innerClassName="flex justify-between items-center"
    >
      <AnimateSharedLayout>
        <motion.ul className="grid grid-flow-col gap-x-8" layout="position">
          {navigation?.data?.main?.map((item, idx) => (
            <NavItem
              key={idx}
              subItems={item?.items as SubItem[]}
              link={item?.primary?.link}
              name={item?.primary?.name}
            />
          ))}
        </motion.ul>
      </AnimateSharedLayout>

      {portalUrl && (
        <Button as={Link} href={portalUrl} variant="primary">
          Patient Portal
        </Button>
      )}
    </BoundedBoxTw>
  )
}

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { AllLocationsQuery } from '../types.gql'

export interface TLocation {
  title?: string
  addressHTML?: string
  phoneNumber?: string
  fax?: string
  hoursHTML?: string
  location?: {
    longitude: number
    latitude: number
  }
}

export function useAllLocations() {
  const queryData = useStaticQuery<AllLocationsQuery>(graphql`
    query AllLocations {
      allPrismicLocation {
        nodes {
          data {
            title {
              text
            }
            address {
              text
              html
            }
            phone_number
            fax
            hours {
              html
            }
            location {
              longitude
              latitude
            }
          }
        }
      }
    }
  `)

  return React.useMemo(() => {
    return queryData.allPrismicLocation.nodes.map((node) => ({
      title: node.data?.title?.text,
      addressHTML: node.data?.address?.html,
      phoneNumber: node.data?.phone_number,
      fax: node.data?.fax,
      hoursHTML: node.data?.hours?.html,
      location: node.data?.location,
    }))
  }, []) as TLocation[]
}

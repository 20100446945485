export const __SERVER__ = typeof window === 'undefined'
export const __CLIENT__ = !__SERVER__
export const __DEV__ = process.env.NODE_ENV === 'development'

export const TI_LEAF_FLUID_OBJ = {
  base64:
    'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wEEEAANABAAEAASABgAEgAZABwAHAAZACIAJQAhACUAIgAyAC4AKgAqAC4AMgBLADYAOgA2ADoANgBLAHEARwBTAEcARwBTAEcAcQBkAHkAYgBcAGIAeQBkALIAjAB8AHwAjACyAM4ArQCkAK0AzgD5AN8A3wD5ATkBKQE5AZkBmQIlEQANABAAEAASABgAEgAZABwAHAAZACIAJQAhACUAIgAyAC4AKgAqAC4AMgBLADYAOgA2ADoANgBLAHEARwBTAEcARwBTAEcAcQBkAHkAYgBcAGIAeQBkALIAjAB8AHwAjACyAM4ArQCkAK0AzgD5AN8A3wD5ATkBKQE5AZkBmQIl/8IAEQgATQBkAwEiAAIRAQMRAf/EABoAAQEBAQEBAQAAAAAAAAAAAAIBAwAEBQb/2gAIAQEAAAAA+aD06WXkg6TZJ3HtxnKSuVs8+XO1PoDfHq7eHWpLwOFappW34mnp31avdyXwN9dda33Lr8LXXR6Nxaq/nVpqns649f/EABkBAAMBAQEAAAAAAAAAAAAAAAABAgMEBv/aAAgBAhAAAADz7GBdCQdMpKV1YZyI68881E//xAAZAQADAQEBAAAAAAAAAAAAAAAAAQIDBAb/2gAIAQMQAAAA8wMA1pKWdcpTM9uGciOrPOJk/8QAHxAAAgMAAgMBAQAAAAAAAAAAAAECERIQIQMTICJR/9oACAEBAAE/ANnsHNmpFvjsp/0yV8ZFBDo65tGiyyxtn6LSHMczRocy+Oyii0jQ/IOYmxPlcaQ/IOZs0W2JC4tG0j2GmUzIooyihGkew1JijJi8Z6xQFEyJGTJqTIwbI+NCghRRlGUUikUVx0RExSExPlIoXNMSEJISEJiOyhREkdFi4ixM0yLF8WIpH//EABsRAQEBAAMBAQAAAAAAAAAAAAABERASIQNR/9oACAECAQE/AMjz849esYnzdXWKyutdVdayPGt4tW161rWqqqtVsf/EAB0RAAIDAQADAQAAAAAAAAAAAAABERIhEAIDUWH/2gAIAQMBAT8Awz5zTSCv6L1soVQ0QyjKDKshGDY+eTG2aSSTxjGMkfP/2Q==',
  aspectRatio: 1.2940330697340043,
  src: 'https://images.prismic.io/surgical-associates-hawaii-dev/e27cabd2-a09f-4799-9ab3-aa85dd40cca1_leaf-bg.jpg?auto=compress%2Cformat&w=800',
  srcSet:
    'https://images.prismic.io/surgical-associates-hawaii-dev/e27cabd2-a09f-4799-9ab3-aa85dd40cca1_leaf-bg.jpg?auto=compress%2Cformat&w=200&h=155 200w, https://images.prismic.io/surgical-associates-hawaii-dev/e27cabd2-a09f-4799-9ab3-aa85dd40cca1_leaf-bg.jpg?auto=compress%2Cformat&w=400&h=309 400w, https://images.prismic.io/surgical-associates-hawaii-dev/e27cabd2-a09f-4799-9ab3-aa85dd40cca1_leaf-bg.jpg?auto=compress%2Cformat&w=800&h=618 800w, https://images.prismic.io/surgical-associates-hawaii-dev/e27cabd2-a09f-4799-9ab3-aa85dd40cca1_leaf-bg.jpg?auto=compress%2Cformat&w=1200&h=927 1200w, https://images.prismic.io/surgical-associates-hawaii-dev/e27cabd2-a09f-4799-9ab3-aa85dd40cca1_leaf-bg.jpg?auto=compress%2Cformat&w=1600&h=1236 1600w',
  sizes: '',
}

export const MAPBOX_API_TOKEN =
  'pk.eyJ1IjoiYXN5YXJiZGV2IiwiYSI6ImNrb3JsaDBncTB6cnIyd3BwazB6NDl6b2EifQ.bczsMNVc5mU4Vkn4fV88Zw'

export const DEFAULT_MAP_CENTER = {
  longitude: 21.350781150679737,
  latitude: -157.8289142004041,
}

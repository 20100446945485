import { VisuallyHidden } from '../VisuallyHidden'
import clsx from 'clsx'

import { BoundedBoxTw } from '../BoundedBox'
import { TLocation, useAllLocations } from '../../hooks/useAllLocations'
import AssetBestDoctors from '../../assets/bestdoctors.jpg'

const Location = ({
  title,
  phoneNumber,
}: Pick<TLocation, 'title' | 'phoneNumber'>) => {
  return (
    <li className="space-y-3 sm:text-center">
      {title && <p className="font-semibold sans-14-1_5">{title}</p>}
      {phoneNumber && (
        <div className="hover:underline focus:underline">
          <a
            href={`tel:${phoneNumber}`}
            className={clsx(
              'block sans-14-1_5',
              'focus:ring focus:outline-none'
            )}
          >
            <VisuallyHidden>Call phone number for: {title}</VisuallyHidden>
            {phoneNumber}
          </a>
        </div>
      )}
    </li>
  )
}

export const ContactInfo = (): JSX.Element => {
  const locations = useAllLocations()

  return (
    <BoundedBoxTw
      className={clsx(
        'col-span-full border-b border-green-48 py-4',
        'sm:col-start-2 sm:col-span-1 sm:pl-6 sm:self-center sm:border-b-0'
      )}
    >
      <div
        className={clsx(
          'flex items-start justify-between',
          'sm:items-center sm:justify-center'
        )}
      >
        <ul className="grid gap-5 mr-4 sm:mr-12 sm:gap-6">
          {locations.map((l, idx) => (
            <Location key={idx} title={l.title} phoneNumber={l.phoneNumber} />
          ))}
        </ul>

        <img
          loading="lazy"
          src={AssetBestDoctors}
          className="object-contain w-[90px] sm:w-[112px]"
          width={112}
          height={58}
        />
      </div>
    </BoundedBoxTw>
  )
}

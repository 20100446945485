import { motion } from 'framer-motion'
import clsx from 'clsx'

import { useHeaderNavigation } from '../../hooks/useHeaderNavigation'
import { Button } from '../Button'
import { Link } from '../Link'
import { useSettings } from '../../hooks/useSettings'

// TODO: use a popover primitive for a11y

export const Overlay = (): JSX.Element => {
  return (
    <motion.div
      initial={{ opacity: 0, pointerEvents: 'none' }}
      animate={{ opacity: 0.75, pointerEvents: 'auto' }}
      exit={{ opacity: 0, pointerEvents: 'none' }}
      transition={{ duration: 0.3, bounce: 0, type: 'spring' }}
      aria-hidden
      className="fixed inset-0 z-10 bg-gray-18 sm:hidden"
    />
  )
}

export const Drawer = ({
  closeMobileNav,
}: {
  closeMobileNav: () => void
}): JSX.Element => {
  const navigation = useHeaderNavigation()
  const settings = useSettings()

  const portalUrl = settings.data?.patient_portal_url

  return (
    <motion.nav
      initial={{ opacity: 0, y: '-10%', pointerEvents: 'none' }}
      animate={{ opacity: 1, y: '0%', pointerEvents: 'auto' }}
      exit={{ opacity: 0, y: '-10%', pointerEvents: 'none' }}
      transition={{ bounce: 0, duration: 0.3, type: 'spring' }}
      onClick={closeMobileNav}
      className={clsx(
        'py-12 px-4 items-center flex flex-col bg-green-84 border-b',
        'border-green-48 absolute top-[90px] inset-x-0 z-20',
        'sm:hidden'
      )}
    >
      <ul className="grid gap-10 mb-10">
        {navigation?.data?.main?.map((item, idx) => (
          <li className="whitespace-nowrap" key={idx}>
            <Link
              href={item?.primary?.link?.url}
              className={clsx(
                'sans-14-1_5 block uppercase text-center',
                'font-semibold tracking-caps transition',
                'hover:text-green-43 focus:text-green-43',
                'focus:outline-none focus:ring'
              )}
            >
              {item?.primary?.name}
            </Link>

            {(item?.items?.length ?? 0) > 0 && (
              <ul className="mt-4">
                {item?.items?.map((subItem, idx) => (
                  <li className="mb-3 last:mb-0" key={idx}>
                    <Link
                      className={clsx(
                        'sans-14-1_5 block text-green-43 font-semibold',
                        'text-center',
                        'hover:underline focus:underline',
                        'focus:outline-none focus:ring'
                      )}
                      href={subItem?.sub_link?.url}
                    >
                      {subItem?.sub_name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>

      {portalUrl && (
        <Button
          as={Link}
          href={portalUrl}
          variant="primary"
          className="inline-block"
        >
          Patient Portal
        </Button>
      )}
    </motion.nav>
  )
}

import { GraphqlIcon } from './icons/GraphqlIcon'
import { RefreshIcon } from './icons/RefreshIcon'
import { EditIcon } from './icons/EditIcon'

import './DevPanel.css'

const refreshData = () => fetch('/__refresh', { method: 'post' })
const openAdmin = () => window.open('/admin')
const openGraphiQL = () => window.open('/__graphql')

const icons = {
  graphql: GraphqlIcon,
  refresh: RefreshIcon,
  edit: EditIcon,
}

type DevButtonProps = {
  icon: keyof typeof icons
  onClick: () => void
  isActive?: boolean
  title: string
  className?: string
}

const DevButton = ({
  icon: iconName,
  onClick,
  isActive = false,
  title,
}: DevButtonProps) => {
  const Icon = icons[iconName]

  return (
    <button
      tabIndex={-1}
      onClick={onClick}
      title={title}
      data-dev-panel-button=""
      data-active={isActive.toString()}
    >
      <Icon data-dev-panel-icon />
    </button>
  )
}

export const DevPanel = () => {
  return (
    <div data-dev-panel>
      <DevButton onClick={openAdmin} icon="edit" title="Open admin" />
      <DevButton onClick={openGraphiQL} icon="graphql" title="Open GraphiQL" />
      <DevButton onClick={refreshData} icon="refresh" title="Refresh data" />
    </div>
  )
}

import { BoundedBoxTw } from './BoundedBox'
import { Link } from './Link'
import { useSettings } from '../hooks/useSettings'
import { useFooterNavigation } from '../hooks/useFooterNavigation'
import clsx from 'clsx'

interface NavItemProps {
  href?: string
  children?: string
}

const NavItem = ({ href, children }: NavItemProps) => {
  return (
    <li>
      <Link
        href={href}
        className={clsx(
          'block uppercase font-semibold tracking-caps transition sans-12-1_5',
          'hover:text-gray-93 focus:text-gray-93 focus:ring focus:outline-none'
        )}
      >
        {children}
      </Link>
    </li>
  )
}

export const Footer = ({
  className,
  ...props
}: React.ComponentProps<'footer'>): JSX.Element => {
  const navigation = useFooterNavigation()
  const settings = useSettings()

  return (
    <BoundedBoxTw
      as="footer"
      maxWidthClassName="max-w-none"
      className={clsx(className, 'bg-green-14 text-white py-8')}
      {...props}
    >
      <nav className="flex flex-col space-y-6 md:flex-row md:justify-between md:space-y-0">
        <ul className="space-y-4 md:flex md:space-x-4 md:space-y-0">
          {navigation?.data?.main?.map((item, idx) => (
            <NavItem key={idx} href={item?.primary?.link?.url}>
              {item?.primary?.name}
            </NavItem>
          ))}
        </ul>

        <small className="block font-semibold uppercase tracking-caps sans-11-1_5">
          © {new Date(Date.now()).getFullYear()}{' '}
          {settings.data?.site_copyright?.text}
        </small>
      </nav>
    </BoundedBoxTw>
  )
}

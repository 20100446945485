import { VisuallyHidden } from '../VisuallyHidden'
import clsx from 'clsx'
import { Hamburger } from '../Hamburger'

interface Props extends React.ComponentProps<'button'> {
  isOpen?: boolean
}

export const HamburgerButton = ({
  isOpen = false,
  ...props
}: Props): JSX.Element => {
  return (
    <button
      className={clsx(
        'relative bg-green-84 flex items-center justify-center border-b',
        'border-green-48 w-[90px] h-[90px] z-20 border-solid',
        'sm:hidden',
        'focus:outline-none focus:ring',
        isOpen && 'border-b-0'
      )}
      {...props}
    >
      <VisuallyHidden>Expand Navigation Menu</VisuallyHidden>
      <Hamburger isOpen={isOpen} />
    </button>
  )
}
